import React from "react";

export default function Projectitem8({ project }) {
  return (
    <div>
      <a target="_blank" href={project.source}>
        <div className="flex justify-between sm:items-center group border-t gap-4 sm:gap-0 py-4 sm:py-2 px-6 sm:px-0 flex-col-reverse sm:flex-row">
          <div className="flex sm:items-center sm:h-52">
            <div className="hidden sm:block">
              {" "}
              <div class="line2"></div>
            </div>
            <div className="flex flex-col items-start">
              <h4 className="text-3xl sm:text-4xl font-bold opacity-60 group-hover:opacity-100 transition-all ">
                {project.title}
              </h4>
              <p className="text-xs sm:text-sm tracking-wider opacity-60 group-hover:opacity-100 transition-all">
                {project.description}
              </p>
            </div>
          </div>
          <div className="sm:w-[480px] relative">
            <img
              className="w-[500px] right-[-180px] min-h-[270px] object-cover sm:right-0  shadow-xl top-[1.1rem] sm:top-0 z-20 static sm:relative sm:invisible sm:group-hover:visible transition-all transform sm:group-hover:-translate-x-6 transition duration-400 sm:opacity-100 group-hover:opacity-100 grayscale group-hover:grayscale-0"
              src={project.image.url}
            />
          </div>
        </div>
      </a>
    </div>
  );
}
