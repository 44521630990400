import React from "react";
import { Helmet } from "react-helmet";
import ProjectsFull from "components/MainPage/ProjectsFull";

export default function MyProjects() {
  return (
    <div data-scroll-section>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Projects | Eren Yalçın</title>
        <meta
          name="description"
          content="You can reach the jobs I have worked on before. You can have predictions about the work that will emerge from my references."
        />
      </Helmet>
      <ProjectsFull />
    </div>
  );
}
