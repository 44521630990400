import React, { useRef, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { ImDownload } from "react-icons/im";
import ScramblingText from "./ScramblingText";
import ScramblingTextTitle from "./ScramblingTextTitle";

export default function HeroSection({ isDarkMode }) {
  return (
    <div data-scroll-section data-scroll className=" top-0 ">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Home | Eren Yalçın</title>
        <meta
          name="description"
          content="I am a web developer with current technologies. Designing interfaces and doing things differently than everyone else is my motto."
        />
      </Helmet>

      <div className="grid sm:flex justify-between p-2 pt-12 sm:pt-0 px-8 sm:px-32 2xl:px-48 items-center h-screen">
        <div
          className="z-20 order-2 md:order-1"
          data-scroll
          data-scroll-speed="1"
        >
          <h1 className="text-xl sm:text-5xl font-bold">
            <ScramblingText />
            <br />
            <div>
              <ScramblingTextTitle />
            </div>
          </h1>
          <p className="text-sm md:text-xl mt-2">
            Hello, I am Eren, I work as a web developer with current
            technologies.
            <br /> Designing interfaces and doing things differently than
            everyone else is my motto.
          </p>
          <a
            href="https://read.cv/erenyalcn"
            target="_blank"
            class={
              !isDarkMode
                ? "text-white bg-[#050708] hover:bg-[#050708]/90 outline-none  font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 mb-2 mt-4 transition-all"
                : "transition-all text-black bg-[#f6f6f6] hover:bg-[#a1a1a1]/90 outline-none  font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 mb-2 mt-4"
            }
          >
            <ImDownload
              className="mr-2"
              size={18}
              color={!isDarkMode ? "white" : "black"}
            />
            See My CV
          </a>
        </div>
        <div
          data-scroll
          data-scroll-speed="5"
          data-scroll-direction="horizontal"
          className={
            isDarkMode
              ? "w-[250px] h-[250px] sm:w-[700px] sm:h-[450px] top-28 sm:top-none  rounded-full absolute bg-gray-300  left-0 right-0 m-auto sm:my-auto bg-opacity-20"
              : " w-[250px] h-[250px] sm:w-[700px] sm:h-[450px] top-28 sm:top-none rounded-full absolute bg-white left-0 right-0 m-auto sm:my-auto bg-opacity-60"
          }
        ></div>
      </div>
    </div>
  );
}
