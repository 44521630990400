import React from "react";
import { Helmet } from "react-helmet";

export default function Hakkimda() {
  return (
    <div data-scroll-section className="sm:mt-16 mb-8">
      <Helmet>
        <meta charSet="utf-8" />
        <title>About Me | Eren Yalçın</title>
        <meta
          name="description"
          content="You can find information about what I like to do, my hobbies, the setup I use and much more."
        />
      </Helmet>
      <div className="sm:flex justify-around p-2 px-8 sm:px-8 2xl:px-12 ">
        <div className="left-0 relative">
          <h3 className="text-6xl sm:text-8xl font-bold absolute opacity-20">
            About Me
          </h3>
          <p className="text-md mt-10 tracking-wider">
            Web Front-End Developer
          </p>
          <h3 className="text-2xl tracking-widest font-thin max-w-[1200px]">
            I am a Front-End developer in the web field. HTML, CSS, JS and I
            continue to work on React and catch up with innovations, I try to
            adapt to innovations and keep up with development. On the one hand,
            I prepare e-commerce sites and sell them to my customers. I'm doing
            it. Images and videos I will use for Front-End Advanced Adobe
            Photoshop, Illustrator to create I know. On the other hand, After
            Effect and Premiere software also I'm constantly trying to improve
            myself. Being original, different from everyone else I try to do
            different things, in short, I try to do stylish things. As a sector,
            the thing that attracted my attention the most was E-Commerce.
            Everything is one It starts with coffee
          </h3>
        </div>
      </div>
    </div>
  );
}
