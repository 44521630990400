import React, { useState } from "react";
import { Icon } from "Icons";
import { NavLink } from "react-router-dom";
import { Cross as Hamburger } from "hamburger-react";

export default function Header({ isDarkMode }) {
  const [isOpen, setOpen] = useState(false);

  let menu;
  if (isOpen) {
    menu = (
      <div
        className={
          !isDarkMode
            ? "bg-cwhite min-w-full min-h-full fixed z-50 overflow-y-hidden"
            : "bg-black text-textblackmenu min-w-full min-h-full fixed z-50 overflow-y-hidden"
        }
      >
        <nav className="flex items-center w-full h-screen menu-nav">
          <ul className="flex flex-col gap-x-4 font-bold text-[3.5rem] sm:text-[5rem] gap-y-5 px-8 sm:px-[50px] uppercase text-customtext">
            <li className="hover:text-custommenu transition-all">
              <NavLink
                to={"/"}
                exact
                activeClassName="text-activemenu transition-all"
                className="flex items-baseline gap-x-2 md:gap-x-6 justify-between	"
              >
                <p>Homepage</p>
                <i>
                  <svg
                    width="27"
                    height="27"
                    viewBox="0 0 27 27"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4 4H24V24"
                      stroke="#8C8B8B"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M4 24L24 4"
                      stroke="#8C8B8B"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </i>
              </NavLink>
            </li>
            <li className="hover:text-custommenu transition-all">
              <NavLink
                to={"/aboutme"}
                activeClassName="text-activemenu  transition-all"
                className="flex items-baseline gap-x-2 md:gap-x-6 justify-between	relative pointer-events-none"
              >
                <p>About Me</p>
                <span>Soon</span>
              </NavLink>
            </li>
            <li className="hover:text-custommenu transition-all">
              <NavLink
                to={"/projects"}
                activeClassName="text-activemenu transition-all"
                className="flex items-baseline gap-x-2 md:gap-x-6 justify-between	"
              >
                <p>Projects</p>
                <i>
                  <svg
                    width="27"
                    height="27"
                    viewBox="0 0 27 27"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4 4H24V24"
                      stroke="#8C8B8B"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M4 24L24 4"
                      stroke="#8C8B8B"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </i>
              </NavLink>
            </li>
            <li className="hover:text-custommenu transition-all">
              <NavLink
                to={"/contact"}
                activeClassName="text-activemenu transition-all"
                className="flex items-baseline gap-x-2 md:gap-x-6 justify-between	"
              >
                <p>Contact</p>
                <i>
                  <svg
                    width="27"
                    height="27"
                    viewBox="0 0 27 27"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4 4H24V24"
                      stroke="#8C8B8B"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M4 24L24 4"
                      stroke="#8C8B8B"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </i>
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
    );
  }
  let location = window.location;
  let lastUrl = location.href;
  new MutationObserver(() => {
    const url = location.href;
    if (url !== lastUrl) {
      lastUrl = url;
      onUrlChange();
    }
  }).observe(document, { subtree: true, childList: true });

  function onUrlChange() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    if (isOpen) {
      setOpen(false);
      document.body.classList.remove("stop-scrolling");
      //location.reload();
    }
  }

  if (isOpen == true) {
    document.body.classList.add("stop-scrolling");

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  } else {
    document.body.classList.remove("stop-scrolling");
  }

  return (
    <header className="text-black fixed w-full top-0 z-50 !mix-blend-difference">
      {menu}
      <div className="flex justify-between items-center px-4 sm:px-[50px] absolute w-full">
        <a href="https://erenyalcn.dev" className="text-black z-50" id="mlogo">
          {!isDarkMode ? (
            <Icon name="logo" size={100} />
          ) : (
            <Icon name="logow" size={100} />
          )}
        </a>
        <div className="block z-50">
          <Hamburger
            color={!isDarkMode ? "black" : "white"}
            toggled={isOpen}
            toggle={setOpen}
          />
        </div>
      </div>
    </header>
  );
}
