import React, { useRef, useState, useEffect } from "react";

import Scrambler from "scrambling-text";

export default function ScramblingTextTitle() {
  const [text, setText] = useState("Frontend Developer");
  const scramblerRef = useRef(new Scrambler());

  useEffect(() => {
    scramblerRef.current.scramble(text, setText);
  }, []);

  return <p className="text-sm sm:text-3xl">{text}</p>;
}
