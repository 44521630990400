import React, { useRef } from "react";
import particles from "../../Image/particles.mp4";
import { BiWorld } from "react-icons/bi";
import {
  AiFillGithub,
  AiFillLinkedin,
  AiOutlineInstagram,
} from "react-icons/ai";
import emailjs from "@emailjs/browser";
import { Fade } from "react-awesome-reveal";
import { Helmet } from "react-helmet";

export default function Contact() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_d2pttbr",
        "template_pm8qpnk",
        form.current,
        "U61fIfVEfL1-C7URP"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div data-scroll-section className="relative w-full h-screen">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Contact | Eren Yalçın</title>
        <meta
          name="description"
          content="If you contact us, we can work together and create great work. I can work freelance for you."
        />
      </Helmet>
      <video
        loop
        autoPlay
        webkit-playsinline
        webkit-playsInline
        playsInline
        muted
        src={particles}
        className="pointer-events-none	absolute z-10 w-[100%] h-[100%] sm:w-full sm:h-full object-cover sm:min-w-max"
      />

      <div class="z-30 relative max-w-screen-xl px-8 grid gap-8 grid-cols-1 md:grid-cols-2 md:px-12 lg:px-16 xl:px-32 py-16 mx-auto text-gray-900 bg flex items-center justify-center h-screen">
        <div class="flex flex-col">
          <div className="mt-16">
            <h2 class="text-4xl lg:text-5xl font-bold leading-tight text-cwhite stroke-2 stroke-white">
              Let's get in touch!
            </h2>
            <div class="text-cwhite mt-8">
              Don't like forms?{" "}
              <a href="mailto:erenyalcn09@gmail.com" class="underline">
                send
              </a>{" "}
              email.
              <h2 class="text-2xl mt-2 font-bold leading-tight text-cwhite stroke-2 stroke-white">
                If you want to create different, creative works.
              </h2>
            </div>
          </div>

          <div class="mt-20 -ml-16">
            <div className=" sm:flex items-center justify-between px-16">
              <div className="text-cwhite flex flex-col">
                <h5>Social Media</h5>
                <div className="flex items-center gap-x-3 mt-4">
                  <div className="text-xl hover:bg-cwhite text-cwhite hover:text-black max-w-max p-[6px] rounded-full transition-all">
                    <a href="https://erenyalcn.dev/">
                      <BiWorld />
                    </a>
                  </div>

                  <div className="text-[19px] hover:bg-cwhite text-cwhite hover:text-black max-w-max p-[6px] rounded-full transition-all">
                    <a href="https://github.com/ErenYalcn">
                      <AiFillGithub />
                    </a>
                  </div>

                  <div className="text-xl hover:bg-cwhite text-cwhite hover:text-black max-w-max p-[6px] rounded-full transition-all">
                    <a href="https://www.linkedin.com/in/erenyalcn/">
                      <AiFillLinkedin />
                    </a>
                  </div>

                  <div className="text-xl hover:bg-cwhite text-cwhite hover:text-black max-w-max p-[6px] rounded-full transition-all">
                    <a href="https://www.instagram.com/erenyalccn">
                      <AiOutlineInstagram />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <form ref={form} onSubmit={sendEmail}>
          <div>
            <span class="uppercase text-sm text-[#ffffff5e] font-bold">
              Your Name
            </span>
            <input
              class="w-full bg-inputg text-cwhite mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
              type="text"
              name="user_name"
              placeholder=""
            />
          </div>
          <div class="mt-8">
            <span class="uppercase text-sm text-[#ffffff5e] font-bold">
              E-Mail
            </span>
            <input
              class="w-full bg-inputg text-cwhite mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
              type="email"
              name="user_email"
            />
          </div>
          <div class="mt-8">
            <span class="uppercase text-sm text-[#ffffff5e] font-bold">
              Message
            </span>
            <textarea
              class="w-full h-32 bg-inputg text-cwhite mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
              name="message"
            ></textarea>
          </div>
          <div class="mt-8">
            <button
              class="uppercase text-sm font-bold tracking-wide bg-inputgg hover:bg-inputg transition-all text-gray-100 p-3 rounded-lg w-full focus:outline-none focus:shadow-outline"
              type="submit"
              value="Send"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
