import React from "react";

import { useEffect, useState } from "react";
import { request } from "graphql-request";
import Projectitem8 from "./Projectitem8";

export default function Projects() {
  const [projects, setProducts] = useState(null);

  useEffect(() => {
    const fetchProducts = async () => {
      const { projects } = await request(
        "https://api-eu-central-1.graphcms.com/v2/cl05l1ghu1xwu01z22ef2azh6/master",
        `
      { 
        projects {
          id
          title
          description
          image {
            url
          }
          source
        }
      }
    `
      );

      setProducts(projects);
    };

    fetchProducts();
  }, []);

  return (
    <div className="mt-16 min-h-[235px] mb-16 overflow-x-hidden">
      <div>
        {!projects ? (
          "🔥"
        ) : (
          <div>
            <ul>
              {projects.map((project) => (
                <li key={project.id}>
                  <Projectitem8 project={project} />
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}
