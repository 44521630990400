import React from "react";
import { FaLink } from "react-icons/fa";

import {
  AiFillGithub,
  AiFillLinkedin,
  AiOutlineInstagram,
} from "react-icons/ai";
import PhoneNumb from "./PhoneNumb";

export default function Footer() {
  return (
    <footer
      data-scroll
      data-scroll-section
      className="bg-black text-white flex flex-col items-center p-12 !bottom-0 overflow-hidden"
    >
      <div className="flex flex-col items-center mt-4 pt-5 z-20">
        <h3 className="text-5xl md:text-9xl text-center	font-outline-2 transition-all">
          Communication matters <br />
          to start good things
        </h3>
        <p className="text-nonact my-6 mt-10 text-center">
          I'm currently available for freelance worldwide. Feel free to contact
          me if you want to collaborate <br />
          on future projects or have a little chat.{" "}
        </p>
        <a
          target="_blank"
          href="mailto:erenyalcn09@gmail.com"
          className="text-xl max-w-fit md:text-5xl w-full md:max-w-[1000px] flex items-center justify-center gap-x-4 border-[1px] border-nonact rounded-2xl py-3 md:py-10 px-4 md:px-8 my-1 hover:bg-cgray hover:border-cgray hover:scale-105 transition-all"
        >
          Contact Me{" "}
          <i>
            <svg
              className="w-[18px] h-[18px] md:w-[27px] md:h-[27px]"
              width="27"
              height="27"
              viewBox="0 0 27 27"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4 4H24V24"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M4 24L24 4"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </i>
        </a>
      </div>

      <div className="flex items-center gap-x-3 mt-8 ">
        <span className="text-nonact text-sm whitespace-nowrap">
          © 2024 All Rights Reserved.
        </span>
      </div>
    </footer>
  );
}
