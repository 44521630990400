import React from "react";
import { Route } from "react-router-dom";
import { Switch } from "react-router-dom/cjs/react-router-dom.min";
import About from "./MainPage/About";
import HeroSection from "./MainPage/HeroSection";
import Projects from "./MainPage/Projects";
import Contact from "./views/Contact";
import Hakkimda from "./views/aboutme";
import MyProjects from "./views/MyProjects";
import { Helmet } from "react-helmet";

export default function Content({ isDarkMode }) {
  return (
    <div className="overflow-x-hidden">
      <Switch>
        <Route exact path={"/"}>
          <HeroSection isDarkMode={isDarkMode} />
          <Projects />
        </Route>

        <Route path={"/aboutme"}>
          <Hakkimda />
        </Route>

        <Route path={"/projects"}>
          <MyProjects />
        </Route>

        <Route path={"/contact"}>
          <Contact />
        </Route>
      </Switch>
    </div>
  );
}
