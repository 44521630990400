import Content from "components/Content";
import Footer from "components/Footer";
import { Icon } from "Icons";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import React, { useState, useRef } from "react";
import Header from "components/Header";
import "locomotive-scroll.css";

import { DarkModeSwitch } from "react-toggle-dark-mode";
import { LocomotiveScrollProvider } from "react-locomotive-scroll";

function App() {
  const isDarkMode = localStorage.getItem("darkMode") === "true";

  const [darkMode, setDarkMode] = useState(isDarkMode);

  const toggleDarkMode = (checked) => {
    setDarkMode(checked);

    localStorage.setItem("darkMode", checked ? "true" : "false");
  };

  const containerRef = useRef(null);

  return (
    <LocomotiveScrollProvider
      options={{
        smooth: true,
        direction: "vertical",
        lerp: 0.05,
        multiplier: 1,
        scrollFromAnywhere: false,
        smartphone: {
          smooth: true,
        },
        tablet: {
          smooth: true,
        },
        repeat: true,
      }}
      watch={[]}
      containerRef={containerRef}
    >
      <main data-scroll-container ref={containerRef}>
        <div
          ref={containerRef}
          className={
            !isDarkMode ? "App bg-cwhite" : "App bg-[#040404] !text-white"
          }
        >
          <Router>
            <Switch>
              <Header isDarkMode={isDarkMode} />
            </Switch>
            <Content isDarkMode={isDarkMode} />
            <Footer />
            <div className="relative pointer-events-none">
              <Icon name="dot" size={180} />
              <Icon name="circletwo" size={300} />
            </div>
            <div className="z-[999] fixed bottom-4 right-8 dmswitch">
              <DarkModeSwitch
                style={{ marginBottom: "0.9rem" }}
                checked={isDarkMode}
                onChange={toggleDarkMode}
                size={35}
              />
            </div>
          </Router>
        </div>
      </main>
    </LocomotiveScrollProvider>
  );
}
export default App;
