import React, { useRef, useState, useEffect } from "react";

import Scrambler from "scrambling-text";

export default function ScramblingText() {
  const [text, setText] = useState("Eren Yalçın");
  const scramblerRef = useRef(new Scrambler());

  useEffect(() => {
    scramblerRef.current.scramble(text, setText);
  }, []);

  return <span className="text-xl md:text-8xl">{text}</span>;
}
